
import "./pen.css";

const Pen = ({title, image, link}) => {

    const penStyle = {
        backgroundImage: `url(${image})`
    }

    return (
        <div className="pen">
            <a href={link} target="_blank">
                {/* <img src={image} /> */}
                <div class="image" style={penStyle}></div>
                <div className="over flex">{title}</div>
            </a>
        </div>
    )
}

export default Pen;

