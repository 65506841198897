import "./quote.css";

const Quote = () => {
    return (
        <div className="quoteWrapper">
            <h2 className="title">“If you can’t<br /> do what you<br /> imagine,<br /> then what is<br /> imagination<br /> to you?"</h2>
            <p>- Kid Cudi</p>
      </div>
    )
}

export default Quote;
