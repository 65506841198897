import {useRef, useEffect} from "react"
import "./intro.css"
import gsap from "gsap"
import {ScrollTrigger} from "gsap/ScrollTrigger"
import {TweenMax, Power3} from 'gsap'
gsap.registerPlugin(ScrollTrigger)


const Intro = () => {

let introText = useRef(null);
let circleMain = useRef(null);
const title = useRef(null);


useEffect(() => {
    gsap.fromTo(introText, {top: '0',position: 'relative'}, {top: '10px', duration: 2})
    gsap.to(circleMain, {top: '-50vh', duration: 10})
    // gsap.to(circle, {
    //     scrollTrigger: {
    //       trigger: circle,
    //       toggleActions: "restart pause reverse pause",
    //       start: "top top",s
    //       // end: "bottom bottom",
    //       scrub: 0
    //     },
    //     y: 300,
    //     // bottom: '15vh',
    //     // width: '300vw',
    //     // height: '90vh',
    //     // left: '-100vw',
    //     duration: 3
    // })

    // introText.style.color = "red"
    // introText.addEventListener('click',() => {
    //     alert('df');
    // });


}, [])


    return (
        <div className="introWrapper">
            <article>
                {/* <h2 className="intro" ref={el => {introText = el}}> Hey! I'm Kinnie</h2> */}
                <h2 className="intro subTitle" ref={el => {introText = el}}><span class="emo">&#128205;</span> Washington DC</h2>

                <h1 className="title" ref={title}>Fullstack Developer &<br /> UI/UX Designer
                {/* <div className="i-title">
                    <div className="i-title-wrapper title">
                        <div className="i-title-item">Web Designer</div>
                        <div className="i-title-item">UI/UX Designer</div>
                        <div className="i-title-item">Product Designer</div>
                        <div className="i-title-item">Photographer</div>
                    </div>
                </div> */}
                </h1>
            </article>
            <div className="bg" ref={el => {circleMain = el}}></div>
            <div className="bg2"></div>

        </div>
    )
}

export default Intro
