import Work from '../work/Work';
import {portfolio} from '../../data.js';

const WorkList = () => {
 return (
    <div id="work">
        {/* <h1>Title here</h1>
        <p>copy here</p> */}
        {/* <h2 className="subTitle">Some Recent Work</h2> */}
        {portfolio.map((item) => (
            <Work key={item.id} title={item.title} img={item.img} videos={item.videos} copy={item.copy} services={item.services} link={item.link} />
        ))}

    </div>
 )
}

export default WorkList