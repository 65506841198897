import {useState, useEffect, Component} from "react";

import logo from './logo.svg';
import './App.css';
import Intro from './components/intro/Intro';
import Bio from './components/bio/Bio';
import WorkList from './components/workList/WorkList';
import Contact from './components/contact/Contact';
import { Header } from './components/header/Header';
import Quote from './components/quote/Quote';
import { Footer } from './components/footer/Footer';
import PenList from './components/penList/PenList';

function App() {

  const [bgColor, setBgColor] = useState(0)

  return (
    <div>
      <Header />
      <Intro />
      <Bio />
      <Quote />
      <WorkList />
      <Contact />
      <PenList />
      <Footer />
    </div>
  );
}

export default App;
