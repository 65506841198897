import Pen from "../pen/Pen";
import {codepens} from "../../data.js";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper React components
import 'swiper/css';
import "swiper/css/free-mode";
import 'swiper/css/navigation';
import "swiper/css/pagination";

import { Navigation, Pagination, Mousewheel, FreeMode, Scrollbar, A11y } from 'swiper';

const PenList = () => {
    return (
        <div id="codepen" className="pensWrapper">
            <h2 className="subTitle">Popular Codepens <span class="emo">&#128293;&#128293;&#128293;</span></h2>
    <Swiper
        slidesPerView={1}
        spaceBetween={30}
        spaceAround={30}
        freeMode={true}
        mousewheel={true}
        pagination={{
          clickable: true,
        }}
        modules={[Mousewheel, FreeMode, Pagination]}
        className="mySwiper"
      // slidesPerView={2}
      // spaceBetween={10}
      // navigation
      // onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
      breakpoints={
        {
            // 640: {
            //   width: 640,
            //   slidesPerView: 1,
            // },
            480: {
            //   width: 768,
              slidesPerView: 3,
              spaceBetween: 30
            },
          }
      }
    >
        {codepens.map((item) => (
            <SwiperSlide>
                <Pen key={item.id} title={item.title} image={item.img} link={item.link} />
            </SwiperSlide>
        ))}
    </Swiper>


            {/* <div className="pensWrapper">
                {codepens.slice(0,4).map((item) => (
                    <Pen key={item.id} title={item.title} image={item.img} link={item.link}/>
                ))}
            </div> */}
        </div>
    )
}

export default PenList;

