import React, { useState, Component } from 'react'
import "./header.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrClose } from "react-icons/gr";




export const Header = () => {
    const logo = "<KB />";
    const [navbar,setNavbar] = useState(false);
    const [menu_class,setMenuClass] = useState("menu");
    const [ham_class,setHamClass] = useState("ham");

    const [isMenuActive, setIsMenuActive] =  useState(false);


    const udateMenu = ()  => {    
        if(!isMenuActive){
            setHamClass("ham active");
            setMenuClass("menu activeMobile");
            setIsMenuActive(true);
        } else {
            setHamClass("ham");
            setMenuClass("menu");
            setIsMenuActive(false);
        }
    }

    const clearMenu = () => {
        setHamClass("ham");
        setMenuClass("menu");
        setIsMenuActive(false);
    }




    const fixedHeader = () => {
        // console.log(window.scrollY);s
        if (window.scrollY >= 200) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };

    window.addEventListener("scroll", fixedHeader);
    return (
        <header className={ navbar ? "header active" : "header"}>
            <p className="logo title">{logo}</p>
            {/* <div className="ham"><span></span><span></span></div> */}
            
            <menu className={menu_class}>
                <a href="#about" onClick={clearMenu}>About</a>
                <a href="#work" onClick={clearMenu}>Work</a>
                <a href="#codepen" onClick={clearMenu}>CodePens</a>
                <a href="#contact"><button>Contact</button></a>
            </menu>
            <div className={ham_class}>
                <i className="open" onClick={udateMenu}><GiHamburgerMenu /></i>
                <i className="close" onClick={udateMenu}><GrClose /></i>
            </div>

        </header>
    )
}