import "./work.css";
import { useRef, useEffect } from "react";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import { BsArrowRight } from "react-icons/bs";

gsap.registerPlugin(ScrollTrigger);

const Work = ({title,copy,img,videos,services,link}) => {
    const workItem = useRef([]) 
    const wrapperMain = useRef(null)
    const infoWrapper = useRef(null)

    let videoTemplate;
    if (videos) {
        videoTemplate = videos.map((item, index) =>
            // <li key={index}>{item.title}</li>
            // console.log(item)
        <video key={index} controls autoPlay muted loop>
          <source src={item} type="video/mp4"/>
          <source src="movie.ogg" type="video/ogg"></source>
        </video>


        );
    }

    
    useEffect(() => {
        
        const el = workItem.current;

    }, [])

    return (
        <div className={`${title} itemWrapper`} ref={wrapperMain}>
            <div className="info" ref={infoWrapper}>
                {/* {services.map((service, index) => (
                    <span key={index}>{service}</span>
                ))} */}
                {services.map((service, index) => (
                    <div className="service" key={index}>{service}</div>
                ))}
                <h2 className="title">{title}</h2>
                <p>{copy}</p>                
                {link ? <a href={link} target="_blank" className="visitLink">Visit Website <BsArrowRight /></a> : ""}
            </div>
            {/* {services} */}
            <div className="images">
                {videoTemplate}
                {img.map((pic, index) => (
                    <img
                     key={index}
                     src={pic}
                     ref={(el) => {workItem.current[index] = el}} />
                ))}
            </div>
        </div>
    )
}



export default Work
