import brandHorse1 from './img/brandhorse1.png';
import brandHorse2 from './img/brandhorse2.png';
import brandHorse3 from './img/brandhorse3.png';
import brandHorse4 from './img/brandhorse4.png';
import hifStudios1 from './img/hif1.png';
import hifStudios2 from './img/hif2.png';
import hifVideo1 from './img/hifmobile.mp4';
import hifVideo2 from './img/hifvideo3.mp4';
import psb1 from './img/psb1a.png';
import psb2 from './img/psb2a.png';
import psb3 from './img/psb3a.png';
import psb4 from './img/psb4a.png';
import riverHouse1 from './img/riverhouse1.png';
import riverHouse2 from './img/riverhouse2.png';
import riverHouse3 from './img/riverhouse3.png';
import riverHouse4 from './img/riverhouse4.png';
import sp1 from './img/sp1.png';
import sp2 from './img/sp2.png';
import sp3 from './img/sp3.png';
import sp4 from './img/sp4.png';
import sp5 from './img/sp5.png';
import sp6 from './img/sharepoint1.png';
import sp7 from './img/sharepoint2.png';
import sp8 from './img/sharepoint3.png';
import sp9 from './img/sharepoint4.png';
import codepen1 from './img/codepen1.png';
import codepen2 from './img/codepen2.png';
import codepen3 from './img/codepen3.png';



export const about = {
    bio: 'Hello! My name is Brittany and I enjoy creating things that live on the internet. My interest in web development started back in 2012 when I decided to try editing custom Tumblr themes — turns out hacking together a custom reblog button taught me a lot about HTML & CSS! Fast-forward to today, and I’ve had the privilege of working at an advertising agency, a start-up, a huge corporation, and a student-led design studio. My main focus these days is building accessible, inclusive products and digital experiences at Upstatement for a variety of clients. I also recently launched a course that covers everything you need to build a web app with the Spotify API using Node & React. Here are a few technologies I’ve been working with recently'
}

export const codepens = [
    {
        id: 1,
        img: codepen1,
        title: 'Shop Landing Slider',
        link:'https://codepen.io/kinnieb/pres/WYrLGr'
    },
    {
        id: 2,
        img: 'https://shots.codepen.io/kinnieb/pen/QWqreYE-800.jpg?version=1663106151',
        // img:'https://shots.codepen.io/kinnieb/pen/RwaJPjE-800.jpg?version=1624206564',
        title: 'CSS Only Infinite Scrolls',        
        link:'https://codepen.io/kinnieb/live/QWqreYE'
    },
    {
        id: 3,
        img: codepen2,
        title: 'Loud Landing F/W 20',        
        link:'https://codepen.io/kinnieb/live/RwaXGaW'
    },
    {
        id: 4,
        img:'https://shots.codepen.io/kinnieb/pen/NmLwRp-800.webp?version=1556297561',
        title: 'SharePoint Template',
        link:'https://codepen.io/kinnieb/full/NmLwRp'
    },
    {
        id: 5,
        img:'https://shots.codepen.io/kinnieb/pen/WYOeEY-800.webp?version=1543188603',
        title: 'Animated Menu',
        link:'https://codepen.io/kinnieb/live/WYOeEY'
    },
    {
        id: 6,
        img: codepen3,
        title: 'HowBlanksFit Concept',
        link:'https://codepen.io/kinnieb/pres/YzZgzJW/10b06fbaaa62575e727326f18ea2e702?editors=0100'
    },
    {
        id: 7,
        img: 'https://shots.codepen.io/kinnieb/pen/xQxMLL-800.jpg?version=1541300379',
        title: 'GSAP Landing',
        link:'https://codepen.io/kinnieb/live/xQxMLL'
    }
]


export const portfolio = [
    {
        id: 1,
        img: [sp6,sp7, sp8, sp9, sp1,sp4,sp5,sp2],
        title: 'SharePoint Templates',
        copy: 'Custom sharePoint templates and web parts for PAC websites.',
        services: ['Designer','SharePoint Developer'],
    },
    {
        id: 2,
        img: [psb1,psb2,psb3,psb4],
        title: 'PrintShopBuddy',
        copy: 'Print shop management software + Marketplace.',
        services: ['Branding','UI/UX','Front-end Developer'],
    },
    {
        id: 3,
        img: [],
        // img: [hifStudios2,hifStudios1,hifStudios1,hifStudios2],
        title: 'HIF Studios',
        copy: 'Web Ready photos for your online business.',
        services: ['Branding','UI/UX','Fullstack Developer'],
        videos: [hifVideo1,hifVideo2],
    },    // {
    //     id: 2,
    //     img: ['https://shots.codepen.io/kinnieb/pen/RwaJPjE-800.jpg?version=1624206564'],
    //     title: 'HOWBLANKSFIT',
    //     copy: 'Saas + Ecommerce for the imprintable Industry React Native + Node.js development | UI/UX services',
    //     services: ['Product Deisgner','Fullstack Developer']
    // },
    // {
    //     id: 3,
    //     img: ['https://sureshmurali.github.io/Restaurant.f3f8c5fc.png','https://sureshmurali.github.io/Home.5bc04836.png','https://sureshmurali.github.io/AddFood.26b880db.png'],
    //     title: '2Hopkins',
    //     copy: 'sfss fadadf adfadf xvxvvws wefesdd',
    //     services: ['Product Deisgner','Fullstack Developer']
    // },
    {
        id: 4,
        img: [brandHorse1,brandHorse2,brandHorse3,brandHorse4],
        title: 'BrandHorse',
        copy: 'Buy & sell designs and assets for your clothing brand.',
        services: ['UI/UX','Fullstack Developer'],
        // linkk: 'https://google.com'
    },
    {
        id: 5,
        img: [riverHouse1,riverHouse2,riverHouse3,riverHouse4],
        title: 'RiverHouse11',
        copy: 'Custom wordpress theme for River House 11 apartment website.',
        services: ['Wordpress Developer'],
        link: 'https://riverhouse11.com'
    },
    {
        id: 6,
        img: ['https://assets.codepen.io/2191903/2hopkins2.png'],
        title: '2Hopkins',
        copy: 'Custom wordpress theme for 2 Hopkins apartment website.',
        services: ['Wordpress Developer'],
        link: 'https://2hopkinsapts.com'
    },
    {
        id: 7,
        img: ['https://assets.codepen.io/2191903/jefferson1.png','https://assets.codepen.io/2191903/jefferson2.png'],
        title: 'Jefferson Arbors at Broadlands',
        copy: 'Custom wordpress theme for Jefferson Arbors at Broadlands apartment website.',
        services: ['Wordpress Developer'],
        link: 'https://www.arborsatbroadlands.com/',
        videos: ['https://assets.codepen.io/2191903/jefferson.mp4']
    },
    {
        id: 8,
        img: ['https://assets.codepen.io/2191903/legend2.png','https://assets.codepen.io/2191903/legend4.png','https://assets.codepen.io/2191903/legend3.png','https://assets.codepen.io/2191903/legend1.png'],
        title: 'Legend Management Group',
        copy: 'Custom wordpress theme for Legend Management corporate website.',
        services: ['Wordpress Developer'],
        link: 'https://www.legendmanagementgroup.com',
    }
]
// 'https://sureshmurali.github.io/AddFood.26b880db.png'
// export const codepen = [
//     {
//         id: 1,
//         title: 'Vinalla JS Muisc Player',
//         link:''
//     },
//     {
//         id: 2,
//         title: 'ReactJS Muisc Player',
//         link:''
//     }
// ]