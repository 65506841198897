import "./bio.css";
import aws from '../../img/aws.png';
import figma from '../../img/figma.png';
import html5 from '../../img/html5.png';
import css from '../../img/css3.png';
import sass from '../../img/sass.png';
import wp from '../../img/wordpress.png';
import netlify from '../../img/netlify.png';
import next from '../../img/next.png';
import node from '../../img/node.png';
import fire from '../../img/firebase.png';
import flutter from '../../img/flutter.png';
import ps from '../../img/ps.png';
import graphql from '../../img/graphql.png';
import typescript from '../../img/typescript.png';
import js from '../../img/javascript.png';
import sp from '../../img/sharepoint.png';

import reactIcon from '../../img/react.png';

const Bio = () => {
    return (
        <div id="about" className="bioWrapper">
            <article>
                <h2 className="subTitle"><span class="emo">&#129300;</span> about me</h2>
                <div>
                    <p className="copy">Hi, I'm Kinnie. I'm a senior-level designer/developer with a focus on front-end development. I have a passion for building and optimizing interactive experiences on the web. I’ve had the privilege of working with agencies, small businesses, non-profits, and a few start-ups.</p>
                    <p className="copy">I create delightful and impactful human-centered experiences at scale through innovation and product strategy.</p>
                </div>
                    {/* <p className="copy">I give a shit about well-crafted (digital) experiences and designing for good. I'm also big on diversity in tech.</p> */}
                {/* <p className="copy">Hello! My name is Kinnie and I enjoy creating things that live on the internet. My interest in web development started back in 2012 when I decided to try editing custom Tumblr themes — turns out hacking together a custom reblog button taught me a lot about HTML & CSS!</p>  */}
                {/* <p className="copy">Fast-forward to today, and I’ve had the privilege of working at an advertising agency, a start-up, a huge corporation, and a student-led design studio. My main focus these days is building accessible, inclusive products and digital experiences at Upstatement for a variety of clients.</p>  */}
                {/* <p className="copy">I also recently launched a course that cover`s everything you need to build a web app with the Spotify API using Node & React.</p> */}
            </article>
            <aside>
                <picture></picture>
                <h3>Technologies I love</h3>
                <div className="icons">
                    <img src={ps} />
                    <img src={figma} />
                    <img src={html5} />  
                    <img src={css} />
                    <img src={sass} />    
                    <img src={wp} />
                    <img src={netlify} />
                    <img src={next} />
                    <img src={node} />
                    <img src={fire} />
                    <img src={flutter} />
                    <img src={reactIcon} />
                    <img src={aws} />
                    {/* <img src="https://assets.codepen.io/2191903/REACT.png" />
                    <img src="https://assets.codepen.io/2191903/NODE JS.png" />
                    <img src="https://assets.codepen.io/2191903/SAAS.png" />
                    <img src="https://assets.codepen.io/2191903/STRAPI.png" />
                    <img src="https://assets.codepen.io/2191903/STRIPE.png" />
                    <img src="https://assets.codepen.io/2191903/WORDPRESS.png" />
                    <img src="https://assets.codepen.io/2191903/FLUTTER.png" />
                    <img src="https://assets.codepen.io/2191903/FIREBASE.png" /> */}
                    <img src={typescript} />
                    <img src={graphql} />   
                    <img src={js} />      
                    <img src={sp} />                    
                 
                </div>
            </aside>
        </div>
    )
}

export default Bio;