import "./contact.css"
import { useState, useRef }  from "react";
import emailjs from "emailjs-com";


export const Contact = () => {
    const formRef = useRef()
    const [done,setDone] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            "service_exm6ubs",
            "template_4w8ftbr",
            formRef.current,
            "it_0JHNMf6dqSn2SD",
        ).then(
            result => {
                console.log(result.text)
                setDone(true);
            },
            error => console.log(error.text)
        );
    };

    return (
        <div id="contact" className="contactWrapper">
            <h2 className="subTitle"><span class="emo">&#x1F60E;</span> Contact Me</h2>
            <h2 className="title">Get in Touch</h2>
            <form ref={formRef} onSubmit={handleSubmit}>
                <input type="text" placeholder="Name" name="user_name" />
                <input type="text" placeholder="Email" name="user_email" />
                <textarea row="5" placeholder="Message" name="user_message"></textarea>
                <button>Submit</button>
                {done &&  "Thank you..."}
            </form>
        </div>
    )
}

export default Contact

