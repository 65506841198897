import {useState} from "react";

import './footer.css';

export const Footer = () => {
    // const [top, setTop] =  useState(0);

    const scrollTop = () => {
        // alert('dsg');
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }


    // function scrollTop(){
    //     alert('dsg');
    // }

    return (
        <footer>
            <h2>Thanks for visiting <span class="emo">&#128512;</span></h2>
            <button className="toTop" onClick={scrollTop}>To top <span class="emo">&#128640;</span></button>
        </footer>
    )
}
